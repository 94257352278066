@import "theme-factory";

$primary-color: (
  usual-color: #263877,
  lighter-color: #bec3d6,
  darker-color: #17245a,
  is-text-color-light: true,
  is-lighter-color-light: false,
  is-darker-color-light: true,
);
$accent-color: (
  usual-color: #f46519,
  lighter-color: #fcd1ba,
  darker-color: #721c24,
  is-text-color-light: true,
  is-lighter-color-light: false,
  is-darker-color-light: true,
);
$warn-color: (
  usual-color: #ff0000,
  lighter-color: #ffb3b3,
  darker-color: #721c24,
  is-text-color-light: true,
  is-lighter-color-light: false,
  is-darker-color-light: true,
);

// Theme Init
.blue-theme {
  @include custom-mat-theme($primary-color, $accent-color, $warn-color);
}
